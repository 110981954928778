import isEqual from 'lodash/isEqual'
import intersection from 'lodash/intersection'
import maxBy from 'lodash/maxBy'
import minBy from 'lodash/minBy'
import { DirectedGraphError } from './error'

export type Vertex = string

export interface Edge {
  from: Vertex
  to: Vertex
  weight: number
}

interface DirectGraphProps {
  edges: Edge[]
  vertices: Set<Vertex>
}

type EdgeHistory = Map<string, [Edge, Edge]>

type CyclesLIFO = [Vertex, DirectedGraph][]

export class DirectedGraph {
  public edges: Edge[]
  public vertices: Set<Vertex>
  constructor({ edges, vertices }: DirectGraphProps) {
    this.edges = edges
    this.vertices = vertices
  }

  getEdges() {
    return this.edges
  }

  isConnected(): boolean {
    return this.areVerticesEqual(
      Array.from(
        this.getSubgraphConnectedTo(Array.from(this.vertices)[0]).vertices
      ),
      Array.from(this.vertices)
    )
  }

  getSubgraphConnectedTo(start: Vertex): DirectedGraph {
    const visitedVertices: Set<Vertex> = new Set()

    let fifo: Vertex[] = []
    fifo.push(start)

    while (fifo.length > 0) {
      const vertex = fifo.splice(0, 1)[0]
      visitedVertices.add(vertex)

      const forwardVertices: Vertex[] = this.edges
        .filter((edge) => isEqual(edge.from, vertex))
        .map((edge) => edge.to)
        .filter((vertex) => !visitedVertices.has(vertex))

      const backwardVertices: Vertex[] = this.edges
        .filter((edge) => isEqual(edge.to, vertex))
        .map((edge) => edge.from)
        .filter((vertex) => !visitedVertices.has(vertex))

      fifo = [...fifo, ...forwardVertices, ...backwardVertices]
    }

    return new DirectedGraph({
      vertices: visitedVertices,
      edges: this.edges.filter(
        (edge) => visitedVertices.has(edge.from) && visitedVertices.has(edge.to)
      ),
    })
  }

  getReachableVerticesFrom(start: Vertex): Set<Vertex> {
    let visitedVertices: Set<Vertex> = new Set()

    let fifo: Vertex[] = []
    fifo.push(start)

    while (fifo.length > 0) {
      const vertex = fifo.splice(0, 1)[0]

      const forwardVertices: Vertex[] = this.edges
        .filter((edge) => isEqual(edge.from, vertex))
        .map((edge) => edge.to)
        .filter((vertex) => !visitedVertices.has(vertex))

      visitedVertices = new Set([
        ...Array.from(visitedVertices),
        ...forwardVertices,
      ])

      fifo = [...fifo, ...forwardVertices]
    }

    return visitedVertices
  }

  reverse(): DirectedGraph {
    return new DirectedGraph({
      edges: this.edges.map(({ from, to, ...rest }) => ({
        from: to,
        to: from,
        ...rest,
      })),
      vertices: this.vertices,
    })
  }

  checkDFGCorrectness(dfgName: string = 'DFG') {
    if (!this.isConnected()) {
      throw new DirectedGraphError(`The ${dfgName} is not connected.`)
    }
    if (
      new Set(this.edges.map((edge) => edge.to)).size !==
      this.vertices.size - 1
    ) {
      throw new DirectedGraphError(
        `The ${dfgName} has more than one start vertex.`
      )
    }
    if (
      new Set(this.edges.map((edge) => edge.from)).size !==
      this.vertices.size - 1
    ) {
      throw new DirectedGraphError(
        `The ${dfgName} has more than one end vertex.`
      )
    }

    const targets = this.edges.map((edge) => edge.to)
    const sources = this.edges.map((edge) => edge.from)

    const root = Array.from(this.vertices).find(
      (vertex) => !targets.find((target) => target === vertex)
    )
    const sink = Array.from(this.vertices).find(
      (vertex) => !sources.find((source) => source === vertex)
    )

    if (!root) {
      throw new DirectedGraphError(`No root vertex found`)
    }

    if (!sink) {
      throw new DirectedGraphError(`No sink vertex found`)
    }

    const reachableVertices = this.getReachableVerticesFrom(root)
    const verticesWithoutRoot = Array.from(this.vertices).filter(
      (vertex) => vertex !== root
    )
    const reversedReachableVertices =
      this.reverse().getReachableVerticesFrom(sink)
    const verticesWithoutSink = Array.from(this.vertices).filter(
      (vertex) => vertex !== sink
    )

    if (
      !this.areVerticesEqual(
        Array.from(reachableVertices),
        verticesWithoutRoot
      ) ||
      !this.areVerticesEqual(
        Array.from(reversedReachableVertices),
        verticesWithoutSink
      )
    ) {
      throw new DirectedGraphError(`The ${dfgName} is not sound`)
    }
  }

  removeSelfCycles(): DirectedGraph {
    return new DirectedGraph({
      edges: this.edges.filter((edge) => edge.from !== edge.to),
      vertices: this.vertices,
    })
  }

  invertWeights(): DirectedGraph {
    return new DirectedGraph({
      edges: this.edges.map(({ weight, ...rest }) => ({
        ...rest,
        weight: -weight,
      })),
      vertices: this.vertices,
    })
  }

  getSpanningArborescence(
    root: Vertex,
    minimum: boolean = false
  ): DirectedGraph {
    let digraph = new DirectedGraph({
      vertices: this.vertices,
      edges: this.edges,
    })

    if (minimum) {
      digraph = digraph.invertWeights()
    }

    let arborescence: DirectedGraph

    let cyclesLIFO: CyclesLIFO = []
    let edgesHistoryLIFO: EdgeHistory[] = []
    let cycleCounter = 0
    let cycles: Set<DirectedGraph> = new Set<DirectedGraph>()

    do {
      arborescence = new DirectedGraph({
        edges: Array.from(digraph.vertices)
          .filter((vertex) => vertex !== root)
          .map((currentVertex) => {
            const edges = digraph.edges.filter(({ to }) => to === currentVertex)
            if (edges.length > 0) {
              const edge = maxBy(edges, (edge: Edge) => edge.weight)
              return edge
            } else {
              return null
            }
          })
          .filter((edge) => edge !== undefined && edge !== null) as Edge[],
        vertices: digraph.vertices,
      })

      cycles = arborescence.getArborescenceCycles()

      cycles.forEach((cycle) => {
        const collapsedCycle: Vertex = `cycle${cycleCounter++}`

        cyclesLIFO.push([collapsedCycle, cycle])

        let edgesHistory: EdgeHistory = new Map()

        const minEdge = minBy(cycle.edges, 'weight') as Edge

        digraph = new DirectedGraph({
          vertices: new Set([
            ...Array.from(digraph.vertices).filter(
              (vertex) => !cycle.vertices.has(vertex)
            ),
            collapsedCycle,
          ]),
          edges: digraph.edges
            .map((edge) => {
              const fromInCycle = cycle.vertices.has(edge.from)
              const toInCycle = cycle.vertices.has(edge.to)

              if (!fromInCycle && !toInCycle) {
                return edge
              } else if (!fromInCycle && toInCycle) {
                const newEdge: Edge = {
                  from: edge.from,
                  to: collapsedCycle,
                  weight:
                    edge.weight +
                    minEdge.weight -
                    (
                      cycle.edges.find(
                        (cycleEdge) => cycleEdge.to === edge.to
                      ) as Edge
                    ).weight,
                }
                edgesHistory.set(
                  `${newEdge.from}->${newEdge.to}-${newEdge.weight}`,
                  [newEdge, edge]
                )
                return newEdge
              } else if (fromInCycle && !toInCycle) {
                const newEdge: Edge = {
                  from: collapsedCycle,
                  to: edge.to,
                  weight: edge.weight,
                }
                edgesHistory.set(
                  `${newEdge.from}->${newEdge.to}-${newEdge.weight}`,
                  [newEdge, edge]
                )
                return newEdge
              } else {
                return null
              }
            })
            .filter((edge) => edge !== null) as Edge[],
        })

        edgesHistoryLIFO.push(edgesHistory)
      })
    } while (cycles.size !== 0)

    while (cyclesLIFO.length > 0) {
      const cycleItem = cyclesLIFO.pop()

      const edgesHistory = edgesHistoryLIFO.pop()

      if (cycleItem && edgesHistory) {
        const [collapsedCycle, cycle] = cycleItem
        const newEdges = arborescence.edges.map((edge) => {
          const edgeKey = `${edge.from}->${edge.to}-${edge.weight}`
          const edges = edgesHistory.get(edgeKey)
          if (edges) {
            const [, valueEdge] = edges
            return valueEdge
          } else {
            return edge
          }
        })

        arborescence = new DirectedGraph({
          vertices: new Set([
            ...Array.from(arborescence.vertices).filter(
              (vertex) => vertex !== collapsedCycle
            ),
            ...cycle.vertices,
          ]),
          edges: [
            ...newEdges,
            ...cycle.edges.filter((edge) => {
              return !Boolean(
                newEdges.find((newEdge) => newEdge.to === edge.to)
              )
            }),
          ],
        })
      }
    }

    if (minimum) {
      return arborescence.invertWeights()
    } else {
      return arborescence
    }
  }

  private getArborescenceCycles(): Set<DirectedGraph> {
    let digraph = new DirectedGraph({
      edges: this.edges,
      vertices: this.vertices,
    })

    let orphanRemaining = true

    do {
      const fullyConnectedVertices = this.getFullyConnectedVertices(digraph)
      digraph = new DirectedGraph({
        vertices: new Set(fullyConnectedVertices),
        edges: digraph.edges.filter(
          ({ from, to }) =>
            fullyConnectedVertices.includes(from) &&
            fullyConnectedVertices.includes(to)
        ),
      })

      orphanRemaining = fullyConnectedVertices.some(
        (vertex) =>
          !digraph.edges.map((edge) => edge.from).includes(vertex) ||
          !digraph.edges.map((edge) => edge.to).includes(vertex)
      )
    } while (orphanRemaining)

    let visitedVertices: Set<Vertex> = new Set<Vertex>()
    let cycles: Set<DirectedGraph> = new Set<DirectedGraph>()

    while (
      !this.areVerticesEqual(
        Array.from(visitedVertices),
        Array.from(digraph.vertices)
      )
    ) {
      const unvisitedVertex = Array.from(digraph.vertices).find(
        (vertex) => !visitedVertices.has(vertex)
      ) as Vertex

      const cycle = digraph.getSubgraphConnectedTo(unvisitedVertex)

      visitedVertices = new Set([...cycle.vertices, ...visitedVertices])

      cycles.add(cycle)
    }

    return cycles
  }

  private getFullyConnectedVertices(digraph: DirectedGraph): Vertex[] {
    const fromVertices: Set<Vertex> = new Set()
    const endVertices: Set<Vertex> = new Set()

    digraph.edges.forEach(({ from, to }) => {
      fromVertices.add(from)
      endVertices.add(to)
    })

    const intersectionVertices = intersection<Vertex>(
      Array.from(fromVertices),
      Array.from(endVertices)
    )

    return intersectionVertices
  }

  private areVerticesEqual = (verticesOne: Vertex[], verticesTwo: Vertex[]) => {
    if (verticesOne.length !== verticesTwo.length) {
      return false
    }
    for (const vertexOne of verticesOne) {
      if (!verticesTwo.find((vertexTwo) => vertexTwo === vertexOne)) {
        return false
      }
    }
    return true
  }

  filterEdgesTWE(minimum: boolean = false) {
    this.checkDFGCorrectness()

    let digraph = this.removeSelfCycles()
    if (minimum) {
      digraph = digraph.invertWeights()
    }

    const sources = this.edges.map((edge) => edge.from)
    const targets = this.edges.map((edge) => edge.to)

    const root = Array.from(digraph.vertices).find(
      (vertex) => !targets.find((target) => target === vertex)
    ) as Vertex

    const fwMSA = digraph.getSpanningArborescence(root)

    const sink = Array.from(digraph.vertices).find(
      (vertex) => !sources.find((source) => source === vertex)
    ) as Vertex

    const bwMSA = digraph.reverse().getSpanningArborescence(sink).reverse()

    const meg = new DirectedGraph({
      vertices: digraph.vertices,
      edges: [...fwMSA.edges, ...bwMSA.edges],
    })

    if (minimum) {
      return meg.invertWeights()
    } else {
      return meg
    }
  }

  filterEdgesGreedy(minimum: boolean = false): DirectedGraph {
    this.checkDFGCorrectness()

    const digraph = this.removeSelfCycles()

    let mwmfdfg = digraph

    const targets = mwmfdfg.edges.map((edge) => edge.to)
    const sources = mwmfdfg.edges.map((edge) => edge.from)
    const root = Array.from(mwmfdfg.vertices).find(
      (vertex) => !targets.find((target) => target === vertex)
    ) as Vertex

    const sink = Array.from(mwmfdfg.vertices).find(
      (vertex) => !sources.find((source) => source === vertex)
    ) as Vertex

    const edges = minimum
      ? digraph.edges.sort((a, b) => b.weight - a.weight)
      : digraph.edges.sort((a, b) => a.weight - b.weight)

    edges.forEach((edge) => {
      if (
        mwmfdfg.edges.filter((mwmEdge) => mwmEdge.from === edge.from).length >
          1 &&
        mwmfdfg.edges.filter((mwmEdge) => mwmEdge.to === edge.to).length > 1
      ) {
        const firstMatchIndex = mwmfdfg.edges.findIndex(
          (mwmEdge) => mwmEdge.from === edge.from && mwmEdge.to === edge.to
        )
        // const test = mwmfdfg.edges.slice(0, firstMatchIndex)
        // const test2 = mwmfdfg.edges.slice(
        //   firstMatchIndex === mwmfdfg.edges.length - 1
        //     ? mwmfdfg.edges.length
        //     : firstMatchIndex + 1,
        //   mwmfdfg.edges.length
        // )
        const filteredDFGEdges = [
          ...mwmfdfg.edges.slice(0, firstMatchIndex),
          ...mwmfdfg.edges.slice(
            firstMatchIndex === mwmfdfg.edges.length - 1
              ? mwmfdfg.edges.length
              : firstMatchIndex + 1,
            mwmfdfg.edges.length
          ),
        ]
        const filteredDFG = new DirectedGraph({
          vertices: mwmfdfg.vertices,
          edges: filteredDFGEdges,
        })

        const reachableVerticesFromRoot =
          filteredDFG.getReachableVerticesFrom(root)
        const reachableVerticesFromSink = filteredDFG
          .reverse()
          .getReachableVerticesFrom(sink)

        if (
          this.areVerticesEqual(
            Array.from(reachableVerticesFromRoot),
            Array.from(filteredDFG.vertices).filter((vertex) => vertex !== root)
          ) &&
          this.areVerticesEqual(
            Array.from(reachableVerticesFromSink),
            Array.from(filteredDFG.vertices).filter((vertex) => vertex !== sink)
          )
        ) {
          mwmfdfg = filteredDFG
        }
      }
    })

    return mwmfdfg
  }
}
