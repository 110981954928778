import { Layout } from 'antd'
import HomeView from './views/home'

const { Header, Content } = Layout

function App() {
  return (
    <Layout>
      <Header
        style={{
          backgroundColor: '#094183',
          height: 130,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <img
          src='./PRIMARY_A_Vertical_Housed_RGB.png'
          style={{
            width: 100,
          }}
        />
        <h1 style={{ color: '#FFFFFF', marginLeft: 10 }}>
          Canvas Logs Analyser
        </h1>
      </Header>
      <Layout>
        <Content
          style={{
            width: '100%',
            height: 'calc(100vh - 130px)',
            padding: 40,
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <HomeView />
        </Content>
      </Layout>
    </Layout>
  )
}

export default App
