import React from 'react'
import isEqual from 'lodash/isEqual'
import { Button } from 'antd'
import { CheckboxValueType } from 'antd/es/checkbox/Group'
import { useEffect, useState } from 'react'

import KeyFilter from './keyFilter'
import ValueFilter from './valueFilter'
import { FilterContainer } from './styled'

export type AttributeFilterOptions = Record<string, string[]>

interface FilterProps {
  filterOptions?: AttributeFilterOptions
  onFilterChange: (filter: AttributeFilterOptions) => void
}

const AttributeFilter: React.FC<FilterProps> = ({
  filterOptions,
  onFilterChange,
}: FilterProps) => {
  const [filter, setFilter] = useState<AttributeFilterOptions>()
  const [selectedKey, setSelectedKey] = useState<string>()

  const disabled = !Boolean(filterOptions)
  const filterKeyOptions = filterOptions ? Object.keys(filterOptions) : []
  const filterKeyValueOptions =
    filterOptions && selectedKey ? filterOptions[selectedKey] : []
  const filterKeyValue = filter && selectedKey ? filter[selectedKey] : []

  const onValueChange = (values: CheckboxValueType[]) => {
    if (!selectedKey) {
      return
    }

    setFilter({
      ...filter,
      [selectedKey]: values as string[],
    })
  }

  const onKeyChange = (key: string) => {
    setSelectedKey(key)
  }

  const onApplyFilter = () => {
    if (!filter) return
    onFilterChange(filter)
  }

  useEffect(() => {
    if (filterOptions) {
      setFilter(filterOptions)
      setSelectedKey(undefined)
    }
  }, [filterOptions])

  return (
    <FilterContainer>
      <KeyFilter
        options={filterKeyOptions}
        onKeyChange={onKeyChange}
        disabled={disabled}
      />
      <ValueFilter
        options={filterKeyValueOptions}
        onValueChange={onValueChange}
        value={filterKeyValue}
        disabled={disabled}
      />
      <Button onClick={onApplyFilter} disabled={disabled}>
        Apply filter
      </Button>
    </FilterContainer>
  )
}

export default React.memo(AttributeFilter, isEqual)
