import React from 'react'
import * as dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

import {
  InfoPanel,
  InfoPanelProps,
  InfoSection,
  InfoSectionItem,
  ItemDescription,
  ItemValue,
} from './styled'
import { Activity } from '../../data/activityData'

export enum InfoType {
  Activity,
  Link,
}

type NodeFrequency = Pick<
  Activity,
  | 'absoluteFreq'
  | 'caseFreq'
  | 'maxRepetitions'
  | 'caseCoverage'
  | 'startFreq'
  | 'endFreq'
>

type LinkFrequency = Pick<
  Activity,
  'absoluteFreq' | 'caseFreq' | 'maxRepetitions' | 'caseCoverage'
>

type NodePerformance = Pick<
  Activity,
  | 'totalDuration'
  | 'medianDuration'
  | 'meanDuration'
  | 'maxDuration'
  | 'minDuration'
>

type LinkPerformance = Pick<
  Activity,
  | 'totalDuration'
  | 'medianDuration'
  | 'meanDuration'
  | 'maxDuration'
  | 'minDuration'
>

export interface InfoProps extends InfoPanelProps {
  frequency?: NodeFrequency | LinkFrequency
  performance?: NodePerformance | LinkPerformance
  infoType?: InfoType
  title?: string
}

dayjs.extend(duration)

const getDuration = (time: number) => {
  const duration = dayjs.duration(time, 'second')
  const days = duration.asDays()
  const hours = duration.asHours()
  const minutes = duration.asMinutes()
  const seconds = duration.asSeconds()
  if (days > 1) {
    return `${Math.floor(days * 100) / 100} Days`
  }
  if (hours > 1) {
    return `${Math.floor(hours * 100) / 100} Hours`
  }
  if (minutes > 1) {
    return `${Math.floor(minutes * 100) / 100} Minutes`
  }
  return `${Math.floor(seconds * 100) / 100} Seconds`
}
const Info = React.forwardRef<HTMLDivElement, InfoProps>(
  ({ title, visible, styles, frequency, performance }: InfoProps, ref) => (
    <InfoPanel
      title={title}
      bordered={false}
      visible={visible}
      styles={styles}
      ref={ref}
    >
      <InfoSection title='Frequency' bordered={true}>
        {frequency?.absoluteFreq !== undefined && (
          <InfoSectionItem>
            <ItemDescription>Absolute frequency</ItemDescription>
            <ItemValue>{frequency?.absoluteFreq}</ItemValue>
          </InfoSectionItem>
        )}
        {frequency?.caseFreq !== undefined && (
          <InfoSectionItem>
            <ItemDescription>Case frequency</ItemDescription>
            <ItemValue>{frequency?.caseFreq}</ItemValue>
          </InfoSectionItem>
        )}
        {frequency?.maxRepetitions !== undefined && (
          <InfoSectionItem>
            <ItemDescription>Max. repetitions</ItemDescription>
            <ItemValue>{frequency?.maxRepetitions}</ItemValue>
          </InfoSectionItem>
        )}
        {frequency?.caseCoverage !== undefined && (
          <InfoSectionItem>
            <ItemDescription>Case coverage</ItemDescription>
            <ItemValue>{frequency?.caseCoverage}%</ItemValue>
          </InfoSectionItem>
        )}
        {(frequency as NodeFrequency)?.startFreq !== undefined && (
          <InfoSectionItem>
            <ItemDescription>Start frequency</ItemDescription>
            <ItemValue>{(frequency as NodeFrequency)?.startFreq}</ItemValue>
          </InfoSectionItem>
        )}
        {(frequency as NodeFrequency)?.endFreq !== undefined && (
          <InfoSectionItem>
            <ItemDescription>End frequency</ItemDescription>
            <ItemValue>{(frequency as NodeFrequency)?.endFreq}</ItemValue>
          </InfoSectionItem>
        )}
      </InfoSection>
      <InfoSection title='Performance' bordered={true}>
        {performance?.totalDuration !== undefined && (
          <InfoSectionItem>
            <ItemDescription>Total duration</ItemDescription>
            <ItemValue>{getDuration(performance.totalDuration)}</ItemValue>
          </InfoSectionItem>
        )}
        {performance?.medianDuration !== undefined && (
          <InfoSectionItem>
            <ItemDescription>Median duration</ItemDescription>
            <ItemValue>{getDuration(performance.medianDuration)}</ItemValue>
          </InfoSectionItem>
        )}
        {performance?.meanDuration !== undefined && (
          <InfoSectionItem>
            <ItemDescription>Mean duration</ItemDescription>
            <ItemValue>{getDuration(performance.meanDuration)}</ItemValue>
          </InfoSectionItem>
        )}
        {performance?.maxDuration !== undefined && (
          <InfoSectionItem>
            <ItemDescription>Max. duration</ItemDescription>
            <ItemValue>{getDuration(performance.maxDuration)}</ItemValue>
          </InfoSectionItem>
        )}
        {performance?.minDuration !== undefined && (
          <InfoSectionItem>
            <ItemDescription>Min. duration</ItemDescription>
            <ItemValue>{getDuration(performance.minDuration)}</ItemValue>
          </InfoSectionItem>
        )}
      </InfoSection>
    </InfoPanel>
  )
)

export default Info
