import Checkbox, { CheckboxChangeEvent } from 'antd/es/checkbox'
import { CheckboxValueType } from 'antd/es/checkbox/Group'
import { CheckboxGroup, ValueFilterContainer, ValueFilterTitle } from './styled'

interface ValueFilterProps {
  options: string[]
  value: string[]
  disabled: boolean
  onValueChange: (values: CheckboxValueType[]) => void
}

const ValueFilter: React.FC<ValueFilterProps> = ({
  options,
  value,
  disabled,
  onValueChange,
}) => {
  const indeterminate = value.length !== 0 && options.length !== value.length
  const checkAll = value.length !== 0 && options.length === value.length

  const checkboxOptions = options.map((option) => ({
    label: option,
    value: option,
  }))

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    onValueChange(e.target.checked ? options : [])
  }

  return (
    <ValueFilterContainer>
      <ValueFilterTitle>Events</ValueFilterTitle>
      {options.length !== 0 && (
        <>
          <Checkbox
            indeterminate={indeterminate}
            onChange={onCheckAllChange}
            checked={checkAll}
            disabled={disabled}
          >
            Select All
          </Checkbox>
          <CheckboxGroup
            onChange={onValueChange}
            style={{ maxHeight: 'calc(100% - 26px)' }}
            options={checkboxOptions}
            value={value}
            disabled={disabled}
          />
        </>
      )}
    </ValueFilterContainer>
  )
}

export default ValueFilter
