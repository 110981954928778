import dayjs, { Dayjs } from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import uniqWith from 'lodash/uniqWith'
import isEqual from 'lodash/isEqual'
import maxBy from 'lodash/maxBy'

import { AttributeFilterOptions } from '../components/attributeFilter/attributeFilter'
import { DirectedGraph, Edge, Vertex } from './directedGraph'
import {
  GRAPH_NODE_COLORS,
  GRAPH_NODE_PADDING,
  GRAPH_NODE_TEXT_COLORS,
  GRAPH_TEXT_FRONT_FAMILY,
  GRAPH_TEXT_FRONT_SIZE,
  MAX_PATH_STROKE_WIDTH,
  MIN_PATH_STROKE_WIDTH,
  RECT_WIDTH,
} from './constants'

export type CsvRecord = {
  caseID: string
  studentID: string
  studentName: string
  mark: string
  session: string
  week: string
  start: string
  end: string
  activity: string
  module: string
  type: string
  interaction: string
  contributed: string
  url: string
  render: string
  OS: string
}

type ProcessedData = Omit<CsvRecord, 'start' | 'end'> & {
  start: Dayjs
  end: Dayjs
  duration: number
}

type FilterKeys = keyof Omit<CsvRecord, 'start' | 'end'>

export type Activity = {
  name: string
  absoluteFreq: number
  caseFreq: number
  cases: Record<string, number>
  maxRepetitions: number
  caseCoverage?: number
  startFreq?: number
  endFreq?: number
  durations: number[]
  totalDuration: number
  medianDuration?: number
  meanDuration?: number
  maxDuration: number
  minDuration: number
}

export type LinkTarget = {
  activity: string
  cases: Record<string, number>
  durations: number[]
  absoluteFreq: number
  caseFreq: number
  maxRepetitions: number
  caseCoverage?: number
  totalDuration: number
  medianDuration: number
  meanDuration: number
  maxDuration: number
  minDuration: number
}

type Link = {
  source: string
  targets: Record<string, LinkTarget>
}

type Activities = Record<string, Activity>
type Links = Record<string, Link>
type Cases = Record<string, ProcessedData[]>
type TargetLinks = Record<
  string,
  {
    target: string
    sources: Record<string, { name: string; absoluteFreq: number }>
  }
>

export type Data = {
  nodes: GraphNode[]
  links: GraphLink[]
}

export type GraphLink = {
  source: string
  target: string
  data: LinkTarget
  styles?: {
    strokeWidth: number
  }
}

export type GraphNode = {
  activityName: string
  data: Activity
  styles: {
    width: number
    color: string
    textColor: string
  }
  nodeType: NODE_TYPE
}

const TIME_FORMAT = 'DD/MM/YY HH:mm:ss'

export enum NODE_TYPE {
  ACTIVITY,
  START,
  END,
}

export const START_NODE_NAME = 'START'
export const END_NODE_NAME = 'END'

dayjs.extend(customParseFormat)
dayjs.extend(duration)
dayjs.extend(relativeTime)

export class ActivityData {
  private csvData: CsvRecord[]
  private processedData: ProcessedData[]
  private attrFilterOptions: AttributeFilterOptions
  private activities: Activities
  private links: Links
  private targetLinks: TargetLinks
  private cases: Cases
  private uniqueCases: Set<string>
  private data: Data
  private activityPercentage: number
  private linkPercentage: number
  protected canvasEl: HTMLCanvasElement = document.createElement('canvas')
  private startNode: GraphNode
  private endNode: GraphNode

  constructor(
    csvData: CsvRecord[] = [],
    activityPercentage: number,
    linkPercentage: number
  ) {
    this.csvData = csvData
    this.processedData = []
    this.attrFilterOptions = {}
    this.activities = {}
    this.links = {}
    this.targetLinks = {}
    this.cases = {}
    this.uniqueCases = new Set()
    this.data = {
      nodes: [],
      links: [],
    }
    this.activityPercentage = activityPercentage
    this.linkPercentage = linkPercentage

    this.startNode = {
      activityName: START_NODE_NAME,
      data: {
        name: START_NODE_NAME,
        absoluteFreq: 1,
        caseFreq: 1,
        cases: {},
        maxRepetitions: 1,
        caseCoverage: 1,
        startFreq: 1,
        endFreq: 1,
        durations: [],
        totalDuration: 1,
        medianDuration: 1,
        meanDuration: 1,
        maxDuration: 1,
        minDuration: 1,
      },
      styles: this.getNodeStyles(
        { name: START_NODE_NAME } as unknown as Activity,
        {}
      ),
      nodeType: NODE_TYPE.START,
    }

    this.endNode = {
      activityName: END_NODE_NAME,
      data: {
        name: END_NODE_NAME,
        absoluteFreq: 1,
        caseFreq: 1,
        cases: {},
        maxRepetitions: 1,
        caseCoverage: 1,
        startFreq: 1,
        endFreq: 1,
        durations: [],
        totalDuration: 1,
        medianDuration: 1,
        meanDuration: 1,
        maxDuration: 1,
        minDuration: 1,
      },
      styles: this.getNodeStyles(
        { name: END_NODE_NAME } as unknown as Activity,
        {}
      ),
      nodeType: NODE_TYPE.END,
    }

    this.init(csvData)
  }

  init(csvData: CsvRecord[]) {
    const { processedData, filterOptions } = this.preprocessData(csvData)
    const { activities, links, targetLinks } =
      this.getActivitiesAndLinks(processedData)

    this.csvData = csvData
    this.processedData = processedData
    this.attrFilterOptions = filterOptions
    this.activities = activities
    this.links = links
    this.targetLinks = targetLinks

    const data = this.getPercentageFilteredData()
    this.data = data
  }

  preprocessData(data: CsvRecord[]): {
    processedData: ProcessedData[]
    filterOptions: AttributeFilterOptions
  } {
    let filterOptions: Record<string, unknown> = {}
    const processedData = data.map((row) => {
      const { start: rawStart, end: rawEnd, ...restRow } = row
      const start = dayjs(rawStart, TIME_FORMAT)
      const end = dayjs(rawEnd, TIME_FORMAT)
      const duration = end.unix() - start.unix()

      const processedRow: ProcessedData = {
        ...row,
        start,
        end,
        duration,
      }

      Object.keys(restRow).forEach((key) => {
        if (!filterOptions[key]) {
          filterOptions[key] = new Set()
        }
        ;(filterOptions[key] as Set<unknown>).add(restRow[key as FilterKeys])
      })

      return processedRow
    })

    Object.keys(filterOptions).forEach((key) => {
      filterOptions[key] = Array.from(filterOptions[key] as Set<unknown>).sort()
    })

    return {
      processedData,
      filterOptions: filterOptions as AttributeFilterOptions,
    }
  }

  getActivitiesAndLinks(processedData: ProcessedData[] = this.processedData): {
    activities: Activities
    links: Links
    targetLinks: TargetLinks
  } {
    const { activities, cases, uniqueCases } =
      this.getActivitiesAndCases(processedData)

    this.cases = cases
    this.uniqueCases = uniqueCases

    Object.keys(cases).forEach((key) => {
      cases[key].sort((a, b) => (a.start.isBefore(b.start) ? -1 : 1))
    })

    const links: Links = {
      [START_NODE_NAME]: {
        source: START_NODE_NAME,
        targets: {},
      },
    }
    const targetLinks: TargetLinks = {}

    Object.keys(cases).forEach((key) => {
      const caseActivities = cases[key]
      const firstActivity = caseActivities[0]
      const lastActivity = caseActivities[caseActivities.length - 1]

      activities[firstActivity.activity].startFreq = activities[
        firstActivity.activity
      ].startFreq
        ? Number(activities[firstActivity.activity].startFreq) + 1
        : 1

      activities[lastActivity.activity].endFreq = activities[
        lastActivity.activity
      ].endFreq
        ? Number(activities[lastActivity.activity].endFreq) + 1
        : 1

      for (const [index, activity] of caseActivities.entries()) {
        if (index === caseActivities.length - 1) {
          break
        }
        const sourceActivity = activity
        const targetActivity = caseActivities[index + 1]
        const duration =
          targetActivity.start.unix() - sourceActivity.start.unix()
        if (!links[sourceActivity.activity]) {
          links[sourceActivity.activity] = {
            source: sourceActivity.activity,
            targets: {},
          }
        }

        if (
          links[sourceActivity.activity]?.targets?.[targetActivity.activity]
        ) {
          // update absoluteFreq
          links[sourceActivity.activity].targets[
            targetActivity.activity
          ].absoluteFreq += 1

          if (
            links[sourceActivity.activity].targets[targetActivity.activity]
              .cases[targetActivity.caseID]
          ) {
            // update cases
            links[sourceActivity.activity].targets[
              targetActivity.activity
            ].cases[targetActivity.caseID] += 1
          } else {
            // update cases
            links[sourceActivity.activity].targets[
              targetActivity.activity
            ].cases[targetActivity.caseID] = 1

            // update caseFreq
            links[sourceActivity.activity].targets[
              targetActivity.activity
            ].caseFreq += 1
          }

          // update duration
          links[sourceActivity.activity].targets[
            targetActivity.activity
          ].durations.push(duration)

          // update maxRepetitions
          if (
            links[sourceActivity.activity].targets[targetActivity.activity]
              .maxRepetitions <
            links[sourceActivity.activity].targets[targetActivity.activity]
              .cases[targetActivity.caseID]
          ) {
            links[sourceActivity.activity].targets[
              targetActivity.activity
            ].maxRepetitions =
              links[sourceActivity.activity].targets[
                targetActivity.activity
              ].cases[targetActivity.caseID]
          }

          // update totalDuration
          links[sourceActivity.activity].targets[
            targetActivity.activity
          ].totalDuration += duration

          // update maxDuration
          links[sourceActivity.activity].targets[
            targetActivity.activity
          ].maxDuration =
            links[sourceActivity.activity].targets[targetActivity.activity]
              .maxDuration > duration
              ? links[sourceActivity.activity].targets[targetActivity.activity]
                  .maxDuration
              : duration
          // update minDuration
          links[sourceActivity.activity].targets[
            targetActivity.activity
          ].minDuration =
            links[sourceActivity.activity].targets[targetActivity.activity]
              .minDuration > duration
              ? duration
              : links[sourceActivity.activity].targets[targetActivity.activity]
                  .minDuration
        } else {
          links[sourceActivity.activity].targets[targetActivity.activity] = {
            activity: targetActivity.activity,
            cases: {
              [targetActivity.caseID]: 1,
            },
            durations: [duration],
            absoluteFreq: 1,
            caseFreq: 1,
            maxRepetitions: 1,
            totalDuration: duration,
            medianDuration: duration,
            meanDuration: duration,
            maxDuration: duration,
            minDuration: duration,
          }
        }
      }
    })

    Object.values(activities).forEach((activity) => {
      const {
        name,
        absoluteFreq,
        caseFreq,
        maxRepetitions,
        totalDuration,
        medianDuration,
        meanDuration,
        maxDuration,
        minDuration,
        cases,
        durations,
        startFreq,
        endFreq,
      } = activity
      if (startFreq) {
        links[START_NODE_NAME].targets[name] = {
          activity: name,
          cases,
          durations,
          absoluteFreq: startFreq,
          caseFreq: startFreq,
          maxRepetitions,
          totalDuration,
          medianDuration: medianDuration ?? 0,
          meanDuration: meanDuration ?? 0,
          maxDuration,
          minDuration,
        }
      }

      if (endFreq) {
        if (links[name]) {
          links[name].targets[END_NODE_NAME] = {
            activity: END_NODE_NAME,
            cases,
            durations,
            absoluteFreq: endFreq,
            caseFreq: endFreq,
            maxRepetitions,
            totalDuration,
            medianDuration: medianDuration ?? 0,
            meanDuration: meanDuration ?? 0,
            maxDuration,
            minDuration,
          }
        } else {
          links[name] = {
            source: name,
            targets: {
              [END_NODE_NAME]: {
                activity: END_NODE_NAME,
                cases,
                durations,
                absoluteFreq: endFreq,
                caseFreq,
                maxRepetitions,
                totalDuration,
                medianDuration: medianDuration ?? 0,
                meanDuration: meanDuration ?? 0,
                maxDuration,
                minDuration,
              },
            },
          }
        }
      }
    })

    Object.keys(links).forEach((key) => {
      Object.values(links[key].targets).forEach((target) => {
        links[key].targets[target.activity].caseCoverage =
          Math.floor((target.caseFreq / uniqueCases.size) * 10000) / 100
      })
    })

    return {
      activities,
      links,
      targetLinks,
    }
  }

  getActivitiesAndCases(processedData: ProcessedData[] = this.processedData) {
    const activities: Activities = {}
    const uniqueCases = new Set<string>()

    const cases: Cases = {}
    processedData.forEach((row) => {
      uniqueCases.add(row.caseID)
      if (activities[row.activity]) {
        activities[row.activity].absoluteFreq += 1

        // case freq - unique case
        if (activities[row.activity].cases[row.caseID]) {
          activities[row.activity].cases[row.caseID] += 1
        } else {
          activities[row.activity].cases[row.caseID] = 1
          activities[row.activity].caseFreq += 1
        }

        // get maxRepetitions
        if (
          activities[row.activity].maxRepetitions <
          activities[row.activity].cases[row.caseID]
        ) {
          activities[row.activity].maxRepetitions =
            activities[row.activity].cases[row.caseID]
        }

        // total duration
        activities[row.activity].totalDuration += row.duration

        // max duration
        activities[row.activity].maxDuration =
          activities[row.activity].maxDuration < row.duration
            ? row.duration
            : activities[row.activity].maxDuration

        // min duration
        activities[row.activity].minDuration =
          activities[row.activity].minDuration < row.duration
            ? row.duration
            : activities[row.activity].minDuration

        activities[row.activity].durations.push(row.duration)
      } else {
        activities[row.activity] = {
          name: row.activity,
          absoluteFreq: 1,
          caseFreq: 1,
          maxRepetitions: 1,
          cases: {
            [row.caseID]: 1,
          },
          durations: [row.duration],
          totalDuration: row.duration,
          maxDuration: row.duration,
          minDuration: row.duration,
        }
      }

      if (cases[row.caseID]) {
        cases[row.caseID].push(row)
      } else {
        cases[row.caseID] = [row]
      }
    })

    Object.keys(activities).forEach((key) => {
      // case coverage
      activities[key].caseCoverage =
        Math.floor((activities[key].caseFreq / uniqueCases.size) * 10000) / 100
      activities[key].meanDuration =
        activities[key].totalDuration / activities[key].absoluteFreq

      const sortedDurations = activities[key].durations.sort((a, b) =>
        a < b ? -1 : 1
      )
      activities[key].medianDuration =
        sortedDurations[Math.floor((sortedDurations.length - 1) / 2)]
    })

    return {
      activities,
      cases,
      uniqueCases,
    }
  }

  getLinks() {
    return this.links
  }

  getData() {
    return this.data
  }

  getAttributeFilterOptions() {
    return this.attrFilterOptions
  }

  getFilteredActivities(activities: Activities, activityPercentage: number) {
    const activityArray = Object.values(activities)

    if (activityArray.length === 0) {
      return activities
    }
    const sortedActivityGroup = activityArray.reduce<
      { caseFreq: number; activities: string[] }[]
    >((accumulator, currentValue) => {
      const current = {
        activityName: currentValue.name,
        caseFreq: currentValue.caseFreq,
      }

      if (accumulator.length === 0) {
        return [
          {
            caseFreq: current.caseFreq,
            activities: [current.activityName],
          },
        ]
      } else {
        for (const [index, group] of accumulator.entries()) {
          if (current.caseFreq > group.caseFreq) {
            accumulator.splice(index, 0, {
              caseFreq: current.caseFreq,
              activities: [current.activityName],
            })
            break
          } else if (current.caseFreq === group.caseFreq) {
            accumulator[index].activities.push(current.activityName)
            break
          } else if (index === accumulator.length - 1) {
            accumulator.push({
              caseFreq: current.caseFreq,
              activities: [current.activityName],
            })
            break
          }
        }
        return accumulator
      }
    }, [])

    const activityGroupPercentage = 1 / sortedActivityGroup.length

    const numOfDisplayingActivities =
      activityPercentage === 0
        ? 1
        : Math.ceil(activityPercentage / activityGroupPercentage)

    const filteredActivityGroup = sortedActivityGroup.slice(
      0,
      numOfDisplayingActivities
    )

    const filteredActivities: Activities = {}
    filteredActivityGroup.map((group) => {
      group.activities.forEach((activityName) => {
        filteredActivities[activityName] = activities[activityName]
      })
    })

    return filteredActivities
  }

  getFilteredLinks(
    filteredActivities: Activities,
    links: Links,
    linkPercentage: number
  ) {
    const vertices: Set<Vertex> = new Set([
      START_NODE_NAME,
      ...Object.keys(filteredActivities),
      END_NODE_NAME,
    ])

    let edges: Edge[] = []
    let graphLinks: GraphLink[] = []

    Object.keys(links).forEach((key) => {
      const { source, targets } = links[key]
      if (vertices.has(source)) {
        Object.keys(targets).forEach((targetKey) => {
          if (vertices.has(targetKey)) {
            edges.push({
              from: source,
              to: targetKey,
              weight: targets[targetKey].absoluteFreq,
            })
            graphLinks.push({
              source,
              target: targetKey,
              data: targets[targetKey],
            })
          }
        })
      }
    })

    const digraph = new DirectedGraph({
      vertices,
      edges,
    })

    let filteredGraphLinks: GraphLink[] = []

    if (edges.length !== 0) {
      let result = digraph.filterEdgesTWE()
      try {
        result = result.filterEdgesGreedy()
      } catch (err) {
        console.log(err)
      }
      const filteredEdges = uniqWith(result.edges, isEqual)

      const filteredLinks: GraphLink[] = []
      const otherLinks: GraphLink[] = []

      graphLinks.forEach((graphLink) => {
        const isfilterdEdge = filteredEdges.some(
          (edge) =>
            edge.from === graphLink.source && edge.to === graphLink.target
        )
        if (isfilterdEdge) {
          filteredLinks.push(graphLink)
        } else {
          otherLinks.push(graphLink)
        }
      })

      otherLinks.sort((a, b) => b.data.absoluteFreq - a.data.absoluteFreq)

      const selectedLength = Math.ceil(otherLinks.length * linkPercentage)

      filteredGraphLinks = [
        ...filteredLinks,
        ...otherLinks.slice(0, selectedLength),
      ]
    }

    return filteredGraphLinks
  }

  updateCsvData(csvData: CsvRecord[]) {
    this.init(csvData)
  }

  updateAttributeFilter(filter: AttributeFilterOptions) {
    const processedData: ProcessedData[] = []
    this.csvData.forEach((row) => {
      const { start: rawStart, end: rawEnd, ...restRow } = row

      let isFilterOut = false
      for (const key of Object.keys(restRow)) {
        if (!filter[key] || !filter[key].includes(restRow[key as FilterKeys])) {
          isFilterOut = true
          break
        }
      }

      if (isFilterOut) {
        return
      }

      const start = dayjs(rawStart, TIME_FORMAT)
      const end = dayjs(rawEnd, TIME_FORMAT)
      const duration = end.unix() - start.unix()

      const processedRow: ProcessedData = {
        ...row,
        start,
        end,
        duration,
      }

      processedData.push(processedRow)
    })

    const { activities, links, targetLinks } =
      this.getActivitiesAndLinks(processedData)

    this.processedData = processedData
    this.activities = activities
    this.links = links
    this.targetLinks = targetLinks

    const data = this.getPercentageFilteredData()
    this.data = data
  }

  generateData(activityData: Activities, links: GraphLink[]): Data {
    const hasNode = Object.keys(activityData).length !== 0
    const longestActivityName =
      maxBy(Object.values(activityData), (activity) => activity.name.length)
        ?.name ?? ''
    const largestNodeAbsoluteFreq =
      maxBy(Object.values(activityData), (activity) => activity.absoluteFreq)
        ?.absoluteFreq || 0
    const largestLinkAbsoluteFreq =
      maxBy(links, (link) => link.data.absoluteFreq)?.data.absoluteFreq || 1

    const nodeColors = this.getNodeColors(largestNodeAbsoluteFreq)

    const nodes: GraphNode[] = [
      ...(hasNode ? [this.startNode, this.endNode] : []),
      ...Object.keys(activityData).map((key) => ({
        activityName: key,
        data: activityData[key],
        styles: this.getNodeStyles(
          activityData[key],
          nodeColors,
          longestActivityName
        ),
        nodeType: NODE_TYPE.ACTIVITY,
      })),
    ]

    const updatedLinks = links.map((link) => ({
      ...link,
      styles: {
        strokeWidth: Math.min(
          MIN_PATH_STROKE_WIDTH +
            Math.floor(
              (link.data.absoluteFreq / largestLinkAbsoluteFreq) *
                (MAX_PATH_STROKE_WIDTH - MIN_PATH_STROKE_WIDTH)
            ),
          MAX_PATH_STROKE_WIDTH
        ),
      },
    }))

    return {
      nodes,
      links: updatedLinks,
    }
  }

  getPercentageFilteredData(
    activityPercentage: number = this.activityPercentage,
    linkPercentage: number = this.linkPercentage
  ) {
    const filteredActivities = this.getFilteredActivities(
      this.activities,
      activityPercentage
    )

    const filteredGraphLink = this.getFilteredLinks(
      filteredActivities,
      this.links,
      linkPercentage
    )

    const data = this.generateData(filteredActivities, filteredGraphLink)
    return data
  }

  onFilterPercentagesChange(
    activityPercentage: number,
    linkPercentage: number
  ) {
    this.activityPercentage = activityPercentage
    this.linkPercentage = linkPercentage

    const data = this.getPercentageFilteredData()
    this.data = data
    return data
  }

  getNodeColor(absoluteFreq: number, nodeColors: Record<number, string>) {
    const frequencyThrottles = Object.keys(nodeColors).sort(
      (a, b) => Number(a) - Number(b)
    )
    for (let i = 0; i < frequencyThrottles.length; i++) {
      if (absoluteFreq <= Number(frequencyThrottles[i])) {
        const color = nodeColors[Number(frequencyThrottles[i - 1])]
        return {
          color,
          textColor: GRAPH_NODE_TEXT_COLORS[color],
        }
      }
    }
    const color =
      nodeColors[Number(frequencyThrottles[frequencyThrottles.length - 1])]
    return {
      color,
      textColor: GRAPH_NODE_TEXT_COLORS[color],
    }
  }

  getNodeColors(largestAbsoluteFreq: number) {
    const numOfColors = GRAPH_NODE_COLORS.length
    let nodeColors: Record<number, string> = {}
    for (let i = 0; i < numOfColors; i++) {
      nodeColors[Math.floor((largestAbsoluteFreq / numOfColors) * i)] =
        GRAPH_NODE_COLORS[i]
    }
    return nodeColors
  }

  getNodeWidth(name: string) {
    return GRAPH_NODE_PADDING + this.getTextWidth(name)
  }

  getTextWidth(text: string): number {
    const context = this.canvasEl.getContext('2d')
    if (context) {
      context.font = `${GRAPH_TEXT_FRONT_SIZE} ${GRAPH_TEXT_FRONT_FAMILY}`
      const metrics = context.measureText(text)
      return metrics.width
    }
    return 200
  }

  getNodeStyles(
    activity: Activity,
    nodeColors: Record<number, string>,
    longestActivityName: string = ''
  ) {
    const { color, textColor } = this.getNodeColor(
      activity.absoluteFreq,
      nodeColors
    )
    const width = this.getNodeWidth(longestActivityName)
    return {
      width: width < RECT_WIDTH ? RECT_WIDTH : width,
      color,
      textColor,
    }
  }
}
