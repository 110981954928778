import React, { useEffect, useRef, useState } from 'react'
import isEqual from 'lodash/isEqual'
import { Slider } from 'antd'

import { EdgeFilterContainer, SliderContainer, SliderText } from './styled'

type onChangeParams = {
  activityPercentage: number
  pathPercentage: number
}
export interface EdgeFilterProps {
  onChange: ({ activityPercentage, pathPercentage }: onChangeParams) => void
  activityPercentage: number
  pathPercentage: number
}
const EdgeFilter: React.FC<EdgeFilterProps> = ({
  activityPercentage,
  pathPercentage,
  onChange,
}) => {
  const [localActivityPercentage, setLocalActivityPercentage] =
    useState(activityPercentage)
  const [localPathPercentage, setLocalPathPercentage] = useState(pathPercentage)
  const nextChange = useRef<onChangeParams>()

  const onActivityFilterChange = (value: number) => {
    setLocalActivityPercentage(value)
    nextChange.current = {
      activityPercentage: value,
      pathPercentage,
    }
  }

  const onPathFilterChange = (value: number) => {
    setLocalPathPercentage(value)
    nextChange.current = {
      activityPercentage,
      pathPercentage: value,
    }
  }

  const onUpdateChange = () => {
    if (nextChange.current) {
      onChange(nextChange.current)
    }
  }

  useEffect(() => {
    setLocalActivityPercentage(activityPercentage)
  }, [activityPercentage])

  useEffect(() => {
    setLocalPathPercentage(pathPercentage)
  }, [pathPercentage])

  return (
    <EdgeFilterContainer>
      <SliderContainer>
        <SliderText>Activities</SliderText>
        <Slider
          vertical
          value={localActivityPercentage}
          onChange={onActivityFilterChange}
          onAfterChange={onUpdateChange}
        />
        <SliderText>{localActivityPercentage}%</SliderText>
      </SliderContainer>
      <SliderContainer>
        <SliderText>Paths</SliderText>
        <Slider
          vertical
          value={localPathPercentage}
          onChange={onPathFilterChange}
          onAfterChange={onUpdateChange}
        />
        <SliderText>{localPathPercentage}%</SliderText>
      </SliderContainer>
    </EdgeFilterContainer>
  )
}

export default React.memo(EdgeFilter, isEqual)
