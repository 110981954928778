import * as d3 from 'd3'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import isEqual from 'lodash/isEqual'
import { SimulationNodeDatum } from 'd3'

import Info, { InfoProps, InfoType } from './info/info'
import { GraphContainer } from './styled'
import {
  END_NODE_NAME,
  GraphLink,
  GraphNode,
  NODE_TYPE,
  START_NODE_NAME,
} from '../data/activityData'
import { INFO_PANEL_SIZE } from './info/styled'
import { getArcsCurvePeakPosition } from '../data/utils'
import {
  CIRCLE_RADIUS,
  MIN_PATH_STROKE_WIDTH,
  RECT_HEIGHT,
  RECT_WIDTH,
} from '../data/constants'

const getInfoPanelPosition = ({
  offsetX,
  offsetY,
  svgWidth,
  svgHeight,
}: {
  offsetX: number
  offsetY: number
  svgWidth: number
  svgHeight: number
}) => ({
  left:
    offsetX + INFO_PANEL_SIZE.width > svgWidth
      ? offsetX - INFO_PANEL_SIZE.width
      : offsetX,
  top:
    offsetY + INFO_PANEL_SIZE.height > svgHeight
      ? svgHeight - INFO_PANEL_SIZE.height
      : offsetY,
})

type GraphLinkData = GraphLink & { source: GraphNode; target: GraphNode }

const onDisplayInfoPanel = (
  event: any,
  data: GraphNode | GraphLinkData,
  svgWidth: number,
  svgHeight: number,
  infoType: InfoType,
  setInfoProps: React.Dispatch<React.SetStateAction<InfoProps>>
) => {
  event.stopPropagation()

  const { offsetX, offsetY } = event

  let infoProps: InfoProps = {
    visible: true,
    styles: getInfoPanelPosition({
      offsetX,
      offsetY,
      svgWidth,
      svgHeight,
    }),
  }

  if (infoType === InfoType.Activity) {
    const title = (data as GraphNode).activityName
    const nodeData = (data as GraphNode).data
    const frequency = {
      absoluteFreq: nodeData.absoluteFreq,
      caseFreq: nodeData.caseFreq,
      maxRepetitions: nodeData.maxRepetitions,
      caseCoverage: nodeData.caseCoverage,
      startFreq: nodeData.startFreq,
      endFreq: nodeData.endFreq,
    }
    const performance = {
      totalDuration: nodeData.totalDuration,
      medianDuration: nodeData.medianDuration,
      meanDuration: nodeData.meanDuration,
      maxDuration: nodeData.maxDuration,
      minDuration: nodeData.minDuration,
    }
    infoProps.frequency = frequency
    infoProps.performance = performance
    infoProps.title = title
  } else if (infoType === InfoType.Link) {
    const title = `${(data as GraphLinkData).source.activityName} -> ${
      (data as GraphLinkData).target.activityName
    }`
    const linkData = (data as GraphLink).data

    const frequency = {
      absoluteFreq: linkData.absoluteFreq,
      caseFreq: linkData.caseFreq,
      maxRepetitions: linkData.maxRepetitions,
      caseCoverage: linkData.caseCoverage,
    }
    const performance = {
      totalDuration: linkData.totalDuration,
      medianDuration: linkData.medianDuration,
      meanDuration: linkData.meanDuration,
      maxDuration: linkData.maxDuration,
      minDuration: linkData.minDuration,
    }
    infoProps.frequency = frequency
    infoProps.performance = performance
    infoProps.title = title
  }

  infoProps.infoType = infoType

  setInfoProps(infoProps)
}

const getPathIntersection = (d: any) => {
  const { source, target } = d

  const isSourceCircle = source.nodeType !== NODE_TYPE.ACTIVITY
  const isTargetCircle = target.nodeType !== NODE_TYPE.ACTIVITY
  const rectWidth = source.styles.width
  let sourceIntersectionX: number
  let targetIntersectionX: number
  let sourceIntersectionY: number
  let targetIntersectionY: number

  if (source.y === target.y && source.x === target.x) {
    const borderX = source.x + RECT_WIDTH / 2
    const gapX = 50
    const gapY = 20
    return {
      isTargetSelf: true,
      path: `M ${borderX} ${source.y - gapY} C ${borderX + gapX} ${
        source.y - gapY / 2
      } ${borderX + gapX} ${source.y + gapY / 2} ${borderX} ${source.y + gapY}`,
    }
  }

  if (source.y === target.y) {
    sourceIntersectionY = source.y
    targetIntersectionY = source.y
    if (source.x > target.x) {
      sourceIntersectionX = source.x - rectWidth / 2
      targetIntersectionX = target.x + rectWidth / 2
    } else {
      targetIntersectionX = target.x - rectWidth / 2
      sourceIntersectionX = source.x + rectWidth / 2
    }
  }

  if (source.x === target.x) {
    sourceIntersectionX = source.x
    targetIntersectionX = source.x
    if (source.y > target.y) {
      sourceIntersectionY = source.y - RECT_HEIGHT / 2
      targetIntersectionY = target.y + RECT_HEIGHT / 2
    } else {
      targetIntersectionY = target.y - RECT_HEIGHT / 2
      sourceIntersectionY = source.y + RECT_HEIGHT / 2
    }
  }

  const intersectionToX =
    (Math.abs(source.x - target.x) / Math.abs(source.y - target.y)) *
    (RECT_HEIGHT / 2)

  if (intersectionToX > rectWidth / 2) {
    const intersectionToY =
      (Math.abs(source.y - target.y) / Math.abs(source.x - target.x)) *
      (rectWidth / 2)
    if (source.x > target.x) {
      sourceIntersectionX = source.x - RECT_WIDTH / 2
      targetIntersectionX = target.x + RECT_WIDTH / 2
    } else {
      sourceIntersectionX = source.x + RECT_WIDTH / 2
      targetIntersectionX = target.x - RECT_WIDTH / 2
    }
    if (source.y > target.y) {
      targetIntersectionY = target.y + intersectionToY
      sourceIntersectionY = source.y - intersectionToY
    } else {
      targetIntersectionY = target.y - intersectionToY
      sourceIntersectionY = source.y + intersectionToY
    }
  } else {
    if (source.x > target.x) {
      sourceIntersectionX = source.x - intersectionToX
      targetIntersectionX = target.x + intersectionToX
    } else {
      sourceIntersectionX = source.x + intersectionToX
      targetIntersectionX = target.x - intersectionToX
    }
    if (source.y > target.y) {
      targetIntersectionY = target.y + RECT_HEIGHT / 2
      sourceIntersectionY = source.y - RECT_HEIGHT / 2
    } else {
      targetIntersectionY = target.y - RECT_HEIGHT / 2
      sourceIntersectionY = source.y + RECT_HEIGHT / 2
    }
  }
  const sourceToTarget = Math.sqrt(
    Math.pow(Math.abs(source.y - target.y), 2) +
      Math.pow(Math.abs(source.x - target.x), 2)
  )
  if (isSourceCircle) {
    sourceIntersectionX =
      source.x - ((source.x - target.x) * CIRCLE_RADIUS) / sourceToTarget
    sourceIntersectionY =
      source.y - ((source.y - target.y) * CIRCLE_RADIUS) / sourceToTarget
  } else if (isTargetCircle) {
    targetIntersectionX =
      target.x - ((target.x - source.x) * CIRCLE_RADIUS) / sourceToTarget
    targetIntersectionY =
      target.y - ((target.y - source.y) * CIRCLE_RADIUS) / sourceToTarget
  }

  return {
    sourceIntersectionX,
    targetIntersectionX,
    sourceIntersectionY,
    targetIntersectionY,
  }
}
interface GraphProps {
  nodes?: GraphNode[]
  links?: GraphLink[]
}

enum LINK_STATE {
  DEFAULT,
  SELECTED,
  HOVERED,
}

enum LINK_COLORS {
  DEFAULT = '#141414',
  SELECTED = '#EE7B30',
  HOVERED = '#F3A068',
}

const LINK_COLOR = d3.scaleOrdinal(
  [LINK_STATE.DEFAULT, LINK_STATE.SELECTED, LINK_STATE.HOVERED],
  [LINK_COLORS.DEFAULT, LINK_COLORS.SELECTED, LINK_COLORS.HOVERED]
)

const getMarkerEnd = (type: LINK_STATE) => `url(#arrow-${type})`

const Graph: React.FC<GraphProps> = ({ nodes, links }) => {
  const svgElement = useRef<SVGSVGElement>(null)

  const [infoProps, setInfoProps] = useState<InfoProps>({})

  const onInfoClick: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.stopPropagation()
    },
    []
  )

  const resetLinksState = useCallback(() => {
    const links = d3
      .select(svgElement.current)
      .selectAll('#links')
      .selectAll('path')

    links.each(function () {
      d3.select(this).attr('marker-end', getMarkerEnd(LINK_STATE.DEFAULT))
      d3.select(this).attr('stroke', LINK_COLOR(LINK_STATE.DEFAULT))
    })
  }, [])

  useEffect(() => {
    const onWindowClick = () => {
      resetLinksState()
      setInfoProps({
        visible: false,
      })
    }
    window.addEventListener('click', onWindowClick)
    return () => window.removeEventListener('click', onWindowClick)
  }, [])

  useEffect(() => {
    if (!nodes || !links || !svgElement.current) {
      return
    }

    const { clientWidth: width, clientHeight: height } = svgElement.current

    d3.select(svgElement.current).selectAll('*').remove()

    const linksData = links
    const nodesData = nodes

    const center = [width / 2, height / 2]
    const simulation = d3
      .forceSimulation<GraphNode & SimulationNodeDatum>(nodes)
      .force(
        'link',
        d3
          .forceLink<GraphNode & SimulationNodeDatum, GraphLink>(links)
          .id((node, i, nodesData) => node.activityName)
          .distance(300) // set fixed link distance
          .iterations(1)
      )
      .force(
        'collide',
        d3.forceCollide().radius((d) => 300)
      )
      .force('charge', d3.forceManyBody())
      .force('x', d3.forceX(center[0]).strength(0.1))
      .force('y', d3.forceY(center[1]).strength(0.1))

    const handleZoom = (event: any) => {
      d3.selectAll('svg>g').attr('transform', event.transform)
    }

    let zoom = d3.zoom<SVGSVGElement, unknown>().on('zoom', handleZoom)

    const svg = d3
      .select(svgElement.current)
      .attr('viewBox', [0, 0, width, height])
      .call(zoom)

    svg
      .append('defs')
      .selectAll('marker')
      .data([LINK_STATE.DEFAULT, LINK_STATE.SELECTED, LINK_STATE.HOVERED])
      .join('marker')
      .attr('id', (d) => `arrow-${d}`)
      .attr('viewBox', '0 0 10 10')
      .attr('refX', '10')
      .attr('refY', '5')
      .attr('markerWidth', 6)
      .attr('markerHeight', 6)
      .attr('orient', 'auto')
      .append('path')
      .attr('stroke', LINK_COLOR)
      .attr('fill', LINK_COLOR)
      .attr('d', 'M 0 0 L 10 5 L 0 10 z')

    const link = svg
      .append('g')
      .attr('id', 'links')
      .attr('fill', 'none')
      .selectAll('g')
      .data(linksData as GraphLink[])
      .join('g')

    const linkEls = link
      .append('path')
      .attr('stroke', LINK_COLOR(LINK_STATE.DEFAULT))
      .attr('stroke-dasharray', (d) => {
        const startEnd = [START_NODE_NAME, END_NODE_NAME]
        const source = (d as unknown as GraphLinkData).source.activityName
        const target = (d as unknown as GraphLinkData).target.activityName
        if (startEnd.includes(source) || startEnd.includes(target)) {
          return '4,4'
        }
        return 'none'
      })
      .attr(
        'stroke-width',
        (d) => d.styles?.strokeWidth || MIN_PATH_STROKE_WIDTH
      )
      .attr('marker-end', getMarkerEnd(LINK_STATE.DEFAULT))
      .on('click', function (event, data) {
        resetLinksState()
        d3.select(this).attr('marker-end', getMarkerEnd(LINK_STATE.SELECTED))
        d3.select(this).attr('stroke', LINK_COLOR(LINK_STATE.SELECTED))
        onDisplayInfoPanel(
          event,
          data as GraphLinkData,
          width,
          height,
          InfoType.Link,
          setInfoProps
        )
      })
      .on('mouseenter', function () {
        if (d3.select(this).attr('stroke') === LINK_COLOR(LINK_STATE.DEFAULT)) {
          d3.select(this).attr('marker-end', getMarkerEnd(LINK_STATE.HOVERED))
          d3.select(this).attr('stroke', LINK_COLOR(LINK_STATE.HOVERED))
        }
      })
      .on('mouseleave', function () {
        if (d3.select(this).attr('stroke') === LINK_COLOR(LINK_STATE.HOVERED)) {
          d3.select(this).attr('marker-end', getMarkerEnd(LINK_STATE.DEFAULT))
          d3.select(this).attr('stroke', LINK_COLOR(LINK_STATE.DEFAULT))
        }
      })

    const linkText = link
      .append('text')
      .text((data) => data.data.absoluteFreq)
      .attr('fill', 'black')
      .attr('stroke', 'black')
      .attr('stroke-width', 0.5)
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'central')

    const nodeEls = svg
      .append('g')
      .selectAll('g')
      .data(nodesData)
      .join('g')
      // @ts-ignore
      .call(drag())
    // .call(drag(simulation))

    const activityEls = nodeEls.filter(function (d) {
      return ![START_NODE_NAME, END_NODE_NAME].includes(d.activityName)
    })

    const startActivityEl = nodeEls.filter(function (d) {
      return d.activityName === START_NODE_NAME
    })

    const endActivityEl = nodeEls.filter(function (d) {
      return d.activityName === END_NODE_NAME
    })

    activityEls
      .append('rect')
      .attr('stroke', 'black')
      .attr('stroke-width', 1.5)
      .attr('x', (d) => -d.styles.width / 2)
      .attr('y', -RECT_HEIGHT / 2)
      .attr('width', (d) => d.styles.width)
      .attr('height', RECT_HEIGHT)
      .style('fill', (d) => d.styles.color)
      .on('click', (event, data) => {
        resetLinksState()
        onDisplayInfoPanel(
          event,
          data,
          width,
          height,
          InfoType.Activity,
          setInfoProps
        )
      })

    activityEls
      .append('text')
      .text((data) => data.activityName)
      .attr('x', 0)
      .attr('y', -12)
      .attr('fill', (d) => d.styles.textColor)
      .attr('stroke', (d) => d.styles.textColor)
      .attr('stroke-width', 0.5)
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'central')

    activityEls
      .append('text')
      .text((data) => Object.values(data.data.cases).reduce((a, b) => a + b, 0))
      .attr('x', 0)
      .attr('y', 12)
      .attr('fill', (d) => d.styles.textColor)
      .attr('stroke', (d) => d.styles.textColor)
      .attr('stroke-width', 0.5)
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'central')

    const triangle = d3.symbol().type(d3.symbolTriangle).size(250)
    startActivityEl
      .append('circle')
      .attr('stroke', '#177851')
      .attr('stroke-width', 1.5)
      .attr('r', CIRCLE_RADIUS)
      .style('fill', '#24BC7F')
    startActivityEl
      .append('path')
      .attr('d', triangle)
      .attr('fill', '#177851')
      .attr('transform', 'rotate(180)')

    const square = d3.symbol().type(d3.symbolSquare).size(250)
    endActivityEl
      .append('circle')
      .attr('stroke', '#B6462B')
      .attr('stroke-width', 1.5)
      .attr('r', CIRCLE_RADIUS)
      .style('fill', '#E08D79')
    endActivityEl.append('path').attr('d', square).attr('fill', '#B6462B')

    const simulateOnChange = () => {
      linkEls.attr('d', function (d: any) {
        const {
          sourceIntersectionX,
          targetIntersectionX,
          sourceIntersectionY,
          targetIntersectionY,
          isTargetSelf,
          path,
        } = getPathIntersection(d)
        if (isTargetSelf) {
          return path
        }
        const { x, y } = getArcsCurvePeakPosition(
          {
            x: sourceIntersectionX as number,
            y: sourceIntersectionY as number,
          },
          {
            x: targetIntersectionX as number,
            y: targetIntersectionY as number,
          }
        )
        // const dx =
        //   (targetIntersectionX as number) - (sourceIntersectionX as number)
        // const dy =
        //   (targetIntersectionY as number) - (sourceIntersectionY as number)
        // const dr = Math.sqrt(dx * dx + dy * dy)
        // return `M${sourceIntersectionX} ${sourceIntersectionY} A ${dr} ${dr} 0 0 1${targetIntersectionX} ${targetIntersectionY}`
        return `M${sourceIntersectionX} ${sourceIntersectionY} Q ${x} ${y} ${targetIntersectionX} ${targetIntersectionY}`
      })

      linkText
        .attr('x', (d) => {
          return getArcsCurvePeakPosition(
            { x: (d as any).source.x, y: (d as any).source.y },
            { x: (d as any).target.x, y: (d as any).target.y }
          ).x
        })
        .attr('y', (d) => {
          return getArcsCurvePeakPosition(
            { x: (d as any).source.x, y: (d as any).source.y },
            { x: (d as any).target.x, y: (d as any).target.y }
          ).y
        })

      nodeEls.attr('transform', (d: GraphNode & SimulationNodeDatum) => {
        return `translate(${d.x},${d.y})`
      })
    }

    simulation.on('tick', simulateOnChange)

    function drag() {
      // simulation: d3.Simulation<GraphNode & d3.SimulationNodeDatum, undefined>
      // function dragstarted(event: any) {
      //   if (!event.active) simulation.alphaTarget(0.3).restart()
      //   event.subject.fx = event.subject.x
      //   event.subject.fy = event.subject.y
      // }

      function dragged(event: any) {
        // event.subject.fx = event.x
        // event.subject.fy = event.y
        event.subject.x = event.x
        event.subject.y = event.y
        simulateOnChange()
      }

      function dragended(event: any) {
        // if (!event.active) simulation.alphaTarget(0)
        // event.subject.fx = null
        // event.subject.fy = null
        event.subject.x = event.x
        event.subject.y = event.y
        simulateOnChange()
      }

      return (
        d3
          .drag()
          // .on('start', dragstarted)
          .on('drag', dragged)
          .on('end', dragended)
      )
    }

    return () => {
      simulation.stop()
    }
  }, [nodes, links])

  return (
    <GraphContainer>
      <svg ref={svgElement} />
      <Info {...infoProps} onClick={onInfoClick} />
    </GraphContainer>
  )
}

export default React.memo(Graph, isEqual)
