import { Card, CardProps } from 'antd'
import React, { ForwardedRef } from 'react'
import styled from 'styled-components'

export interface InfoPanelProps extends CardProps {
  styles?: {
    top?: number
    right?: number
    bottom?: number
    left?: number
  }
  visible?: boolean
}

export const INFO_PANEL_SIZE = {
  width: 390,
  height: 660,
}

export const InfoPanel = React.forwardRef(
  (props: InfoPanelProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { visible, styles, ...cardProps } = props

    const StyledCard = styled(Card)`
      position: absolute;
      width: 20vw;
      max-width: ${INFO_PANEL_SIZE.width}px;
      max-height: ${INFO_PANEL_SIZE.height}px;
      display: ${() => (visible ? 'block' : 'none')};
      ${() => (styles?.top ? `top: ${styles?.top}px;` : '')};
      ${() => (styles?.right ? `right: ${styles?.right}px;` : '')};
      ${() => (styles?.bottom ? `bottom: ${styles?.bottom}px;` : '')};
      ${() => (styles?.left ? `left: ${styles?.left}px;` : '')};
    `
    return <StyledCard ref={ref} {...cardProps} />
  }
)

export const InfoSection = styled(Card)`
  width: 100%;
  height: 280px;
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.title === 'Frequency'
      ? 'border-radius: 8px 8px 0 0;'
      : 'border-radius: 0 0 8px 8px;'};
`

export const InfoSectionItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4px 0;
  ::before {
    content: ' ';
    position: absolute;
    border: 1px dashed #f0f0f0;
    left: 10%;
    right: 10%;
    bottom: 9px;
    z-index: 1;
  }
`

export const ItemDescription = styled.span`
  background-color: white;
  z-index: 2;
`
export const ItemValue = styled.span`
  font-weight: bold;
  background-color: white;
  z-index: 2;
`
