interface Point {
  x: number
  y: number
}

export const getArcsCurvePeakPosition = (
  source: Point,
  target: Point
): Point => {
  const midPoint: Point = {
    x: (source.x + target.x) / 2,
    y: (source.y + target.y) / 2,
  }

  const distance = Math.sqrt(
    Math.pow(source.x - target.x, 2) + Math.pow(source.y - target.y, 2)
  )

  const radius = (distance / 300) * 40

  if (source.x === target.x && source.y === target.y) {
    return {
      x: source.x + 200,
      y: source.y,
    }
  }

  if (source.x === target.x) {
    return {
      x: midPoint.x + (source.y > target.y ? radius : -radius),
      y: midPoint.y,
    }
  }

  if (source.y === target.y) {
    return {
      x: midPoint.x,
      y: midPoint.y + (source.x > target.x ? radius : -radius),
    }
  }

  const angle = Math.abs((source.y - target.y) / (source.x - target.x))
  const xDistance = Math.sqrt(Math.pow(radius, 2) / (Math.pow(angle, 2) + 1))
  const yDistance = xDistance * angle

  return {
    x: midPoint.x + (source.y > target.y ? -xDistance : xDistance),
    y: midPoint.y + (source.x > target.x ? yDistance : -yDistance),
  }
}
