import { Select } from 'antd'
import { KeyFilterContainer } from './styled'

interface KeyFilterProps {
  options: string[]
  onKeyChange: (key: string) => void
  disabled: boolean
}

const KeyFilter: React.FC<KeyFilterProps> = ({
  options,
  onKeyChange,
  disabled,
}) => {
  const selectOptions = options.map((option) => ({
    label: option,
    value: option,
  }))
  return (
    <KeyFilterContainer>
      <label>Filter by</label>
      <Select
        onChange={onKeyChange}
        style={{ width: 240 }}
        options={selectOptions}
        disabled={disabled}
      />
    </KeyFilterContainer>
  )
}

export default KeyFilter
