import styled from 'styled-components'

export const SliderContainer = styled.div`
  width: 80px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`

export const SliderText = styled.div`
  font-size: 14px;
`
export const EdgeFilterContainer = styled.div`
  background: transparent;
  z-index: 2;
  position: absolute;
  top: 20%;
  right: 30px;
  height: 60%;
  display: flex;
  flex-direction: row;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
`
