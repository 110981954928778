export const GRAPH_TEXT_FRONT_SIZE = '24px'
export const GRAPH_TEXT_FRONT_FAMILY = 'sans-serif'
export const GRAPH_NODE_PADDING = 40

export const GRAPH_NODE_COLORS = [
  '#FFFFFF',
  '#EEAABB',
  '#DA4167',
  '#AA2244',
] as const

export const GRAPH_NODE_TEXT_COLORS: Record<string, string> = {
  '#FFFFFF': '#141414',
  '#EEAABB': '#141414',
  '#DA4167': '#FFFFFF',
  '#AA2244': '#FFFFFF',
} as const

export const RECT_WIDTH = 300
export const RECT_HEIGHT = 60
export const CIRCLE_RADIUS = 25
export const MIN_PATH_STROKE_WIDTH = 4
export const MAX_PATH_STROKE_WIDTH = 10
