import Papa from 'papaparse'
import React, { useCallback, useRef, useState } from 'react'
import { UploadProps, notification } from 'antd'
import { Button, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

import AttributeFilter, {
  AttributeFilterOptions,
} from '../components/attributeFilter/attributeFilter'
import Graph from '../components/graph'
import { ActivityData, CsvRecord, Data } from '../data/activityData'
import EdgeFilter, {
  EdgeFilterProps,
} from '../components/edgeFilter/edgeFilter'
import { FilterContainer, UploadContainer } from '../components/styled'

const DEFAULT_EDGE_FILTER_PERCENTAGES = {
  activityPercentage: 100,
  pathPercentage: 0,
}
const HomeView: React.FC = () => {
  const activityData = useRef<ActivityData>(
    new ActivityData(
      [],
      DEFAULT_EDGE_FILTER_PERCENTAGES.activityPercentage,
      DEFAULT_EDGE_FILTER_PERCENTAGES.pathPercentage
    )
  )

  const [data, setData] = useState<Data>(activityData.current.getData())
  const [attrFilterOptions, setAttrFilterOptionsOptions] =
    useState<AttributeFilterOptions>(
      activityData.current.getAttributeFilterOptions()
    )
  const [edgeFilterPercentages, setEdgeFilterPercentages] = useState<
    Pick<EdgeFilterProps, 'activityPercentage' | 'pathPercentage'>
  >(DEFAULT_EDGE_FILTER_PERCENTAGES)

  const props: UploadProps = {
    name: 'file',
    accept: '.csv',
    showUploadList: false,
    beforeUpload(file) {
      Papa.parse<CsvRecord>(file, {
        header: true,
        complete: (data) => {
          try {
            activityData.current.updateCsvData(data.data)

            setData(activityData.current.getData())
            setAttrFilterOptionsOptions(
              activityData.current.getAttributeFilterOptions()
            )
          } catch (err) {
            notification.error({
              message: 'Failed to create the graph',
              description: (err as Error)?.message,
            })
          }
        },
      })
      return false
    },
  }

  const onAttributeFilterChange = useCallback(
    (filter: AttributeFilterOptions) => {
      try {
        activityData.current.updateAttributeFilter(filter)
        setData(activityData.current.getData())
      } catch (err) {
        notification.error({
          message: 'Failed to update the graph',
          description: (err as Error)?.message,
        })
      }
    },
    []
  )

  const onEdgeFilterChange = ({
    activityPercentage,
    pathPercentage,
  }: {
    activityPercentage: number
    pathPercentage: number
  }) => {
    setEdgeFilterPercentages({
      activityPercentage,
      pathPercentage,
    })

    try {
      const data = activityData.current.onFilterPercentagesChange(
        activityPercentage / 100,
        pathPercentage / 100
      )
      setData(data)
    } catch (err) {
      notification.error({
        message: 'Failed to update the graph',
        description: (err as Error)?.message,
      })
    }
  }

  return (
    <>
      <UploadContainer>
        <Upload {...props}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
      </UploadContainer>
      <FilterContainer>
        <AttributeFilter
          filterOptions={attrFilterOptions}
          onFilterChange={onAttributeFilterChange}
        />
      </FilterContainer>
      <EdgeFilter onChange={onEdgeFilterChange} {...edgeFilterPercentages} />
      <Graph nodes={data?.nodes} links={data?.links} />
    </>
  )
}

export default HomeView
