import styled from 'styled-components'
import {
  GRAPH_TEXT_FRONT_FAMILY,
  GRAPH_TEXT_FRONT_SIZE,
} from '../data/constants'

export const GraphContainer = styled.div`
  background: white;
  position: relative;
  height: 100%;
  overflow: visible;
  flex: 1;

  svg {
    width: 100%;
    height: 100%;

    text {
      font-size: ${GRAPH_TEXT_FRONT_SIZE};
      font-family: ${GRAPH_TEXT_FRONT_FAMILY};
    }

    g {
      rect {
        cursor: pointer;
      }
      text {
        cursor: pointer;
      }
    }

    #links {
      g:hover {
        path {
          opacity: 1;
        }
      }

      path:hover + text {
        fill: #f3a068;
        stroke: #f3a068;
      }

      path {
        opacity: 0.8;
      }

      text {
        font-size: 32px;
        font-family: ${GRAPH_TEXT_FRONT_FAMILY};
      }
    }
  }
`

export const UploadContainer = styled.div`
  position: absolute;
  top: 80px;
  left: 460px;
  z-index: 2;
`

export const FilterContainer = styled.div`
  width: 400px;
  height: 100%;
  position: relative;
  padding: 20px;
  background-color: white;
  border-right: 1px solid #f0f0f0;
  overflow: visible;

  .ant-card-body {
    height: 100%;
  }
`
