import { Checkbox } from 'antd'
import styled from 'styled-components'

export const FilterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const KeyFilterContainer = styled.div`
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  label {
    font-size: 16px;
    font-weight: 500;
  }
`

export const ValueFilterContainer = styled.div`
  width: 100%;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 72px - 32px);
  flex: 1;
`

export const ValueFilterTitle = styled.span`
  font-size: 16px;
  font-weight: 500;
`

export const CheckboxGroup = styled(Checkbox.Group)`
  max-height: calc(100% - 26px);
  display: flex;
  flex-direction: column;
  overflow: auto;

  .ant-checkbox-group-item {
    margin-inline-start: unset;
  }
`
